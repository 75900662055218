<!--
 * @Author: cyy
 * @Date: 2022-01-13 11:38:31
 * @LastEditTime: 2022-01-17 18:18:07
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\detail\detailInstallment.vue
-->
<template>
  <div id="detailInstallment">
    <div class="header">
      <el-row class="first-row">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            名称：{{ installmentDetailsList.name }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            期数：{{ installmentDetailsList.specs | getspecs }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            创建人： {{ installmentDetailsList.uname }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            创建时间： {{ installmentDetailsList.c_time | formatTimeStamp }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            有效期：领券后{{
              installmentDetailsList.receive_duration | formatTimeLength(2)
            }}失效
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="statistics">
      <div class="left">
        <div class="item">
          <div class="vm">
            <div class="name">库存数量</div>
            <div class="number">{{ installmentDetailsList.stock_num }}</div>
          </div>
        </div>
        <div class="item">
          <div class="vm">
            <div class="name">已领取</div>
            <div class="number">{{ installmentDetailsList.receive_num }}</div>
          </div>
        </div>
        <div class="item">
          <div class="vm">
            <div class="name">使用率(%)</div>
            <div class="number">{{ installmentDetailsList.used_rate }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <div class="vt">
            <div class="pieChart">
              <pie-chart
                :pieData="list"
                :options="{
                  legend: {
                    orient: 'vertical',
                    left: 'auto',
                    right: 0,
                    top: 'center',
                    bottom: 'auto',
                    itemGap: 12,
                    itemHeight: 12,
                    itemWidth: 12,
                    icon: 'circle',
                    textStyle: { color: '#333', padding: [5, 3, 3, 3] },
                  },
                  series: [
                    {
                      hoverAnimation: false,
                      radius: [0, '88%'],
                      bottom: 'auto',
                    },
                  ],
                }"
              ></pie-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contain-main">
      <el-tabs v-model="activeName">
        <el-tab-pane label="领/用券管理" name="1">
          <voucherManagement
            @toVoid="toVoid"
            v-if="activeName == 1"
            :installment_id="installment_id"
          ></voucherManagement>
        </el-tab-pane>
        <el-tab-pane label="商品管理" name="2">
          <commodityManagement
            v-if="activeName == 2"
            :installment_id="installment_id"
          ></commodityManagement>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="3">
          <operationLog
            v-if="activeName == 3"
            :installment_id="installment_id"
          ></operationLog>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import voucherManagement from './voucherManagement'
import commodityManagement from './commodityManagement'
import operationLog from './operationLog'
import pieChart from '@/components/dataAnalysis/pieChart'
export default {
  name: 'detailInstallment',

  components: {
    pieChart,
    voucherManagement,
    commodityManagement,
    operationLog,
  },

  data() {
    return {
      activeName: '1',
      installmentDetailsList: {},
      list: [],
    }
  },

  created() {
    this.installmentDetails()
    if (this.$route.query.fromNewInstallment) {
      this.activeName = '2'
    }
  },

  filters: {
    getspecs(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '3期（1.80%）'
          break
        case 2:
          text = '6期（4.50%）'
          break
        case 3:
          text = '12期（7.50%）'
          break
      }
      return text
    },
  },

  computed: {
    installment_id() {
      return this.$route.query.installment_id
    },
  },

  methods: {
    // 作废后数据刷新
    toVoid() {
      this.installmentDetails()
    },

    // 获取详情基础数据
    async installmentDetails() {
      try {
        const { data } = await this.$http({
          url: '/Installment/installmentDetails',
          data: {
            installment_id: this.installment_id,
          },
        })
        this.installmentDetailsList = data
        this.list = [
          {
            value: data.used_num,
            name: '已使用',
            itemStyle: { color: '#4150D8' },
          },
          {
            value: data.un_used_num,
            name: '未使用',
            itemStyle: { color: '#29BE7E' },
          },
          {
            value: data.invalid_num,
            name: '已失效',
            itemStyle: { color: '#ED7C2E' },
          },
        ]
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#detailInstallment {
  min-width: 750px;
  margin: 20px;
  .pieChart,
  .treeChart {
    font-size: 0;
    position: relative;
  }
  .pieChart {
    min-width: 373px;
    height: 174px;
  }
  .header {
    font-size: 12px;
    color: #333333;
    padding: 20px;
    background-color: #fff;
    .first-row {
      margin-bottom: 20px;
    }
  }
  .statistics {
    background-color: #fff;
    margin: 20px 0;
    padding: 20px 90px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .item {
        flex: auto;
        font-size: 0;
        text-align: center;
        > div {
          text-align: left;
          .name {
            font-size: 14px;
            color: #333333;
            margin-bottom: 12px;
          }
          .number {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    .right {
      width: 373px;
    }
  }
  .contain-main {
    background-color: #fff;
    ::v-deep .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
}
</style>