<!--
 * @Author: cyy
 * @Date: 2022-01-13 16:24:27
 * @LastEditTime: 2022-09-06 16:20:14
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\installment\detail\commodityManagement.vue
-->
<template>
  <div id="commodityManagement">
    <pagination2
      :option="form"
      url="/Installment/goodsList"
      ref="childDialogBox"
      class="childDialogBox"
      @complete="complete"
    >
      <template v-slot:default="{ tableData }">
        <div class="header-search">
          <div class="flex-center">
            <el-button type="primary" @click="add">添加商品</el-button>
            <el-button type="" @click="resetList">刷新列表</el-button>
            <span class="redcolor ml10 fs12">
              提示：添加多个商品即表示该券适用于多个商品
            </span>
          </div>

          <div>
            <el-select
              v-model="form.type"
              style="width: 120px"
              class="mr20"
              placeholder="请选择"
              size="medium"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              size="medium"
              v-model="form.name"
              style="width: 218px"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
        </div>

        <el-table
          @sort-change="sortChange"
          class="table"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="商品">
            <template slot-scope="{ row }">
              <div class="menber">
                <div class="tu">
                  <img :src="row.cover_img" alt="" />
                </div>
                <div class="name" :title="row.name">
                  {{ row.name }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="商品类型" prop="course_type">
            <template slot-scope="{ row }">
              <div :title="row.course_type | getType">
                {{ row.course_type | getType }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="商品售价"
            prop="receive_time"
            sortable="custom"
          >
            <template slot-scope="{ row }">
              <span class="redcolor">￥{{ row.price }}</span>
            </template>
          </el-table-column>

          <el-table-column label="学员每期(免息)">
            <template slot-scope="{ row }">
              ￥{{ row.issue }} x {{ specs }}期
            </template>
          </el-table-column>

          <el-table-column label="总承担利息(1.8%)" prop="used_time">
            <div slot="header">总承担利息({{ specsNumber }})</div>
            <template slot-scope="{ row }">￥{{ row.interest }}</template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" @click="remove(row)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="networkschoolSelectStatus = false"
      :installment_id="installment_id"
      @singleselectcontentval="singleselectcontentval"
      tip="提示：分期免息需金额大于100元，系统已自动过滤小于100元的商品和课程"
      title="添加免息商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="10"
      :id_type="4"
      :goodlistType="true"
    ></networkschoolSelect>
  </div>
</template>

<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'commodityManagement',

  components: {
    networkschoolSelect,
  },

  data() {
    return {
      list: [],
      networkschoolSelectStatus: false,
      specsNumber: '',
      specs: '',
      form: {
        installment_id: this.installment_id,
        type: 0,
        sort: '',
        name: '',
      },
      // 0全部 1直播课 2小班课 3录播课 4系列课 5公开课 6知识商品
      options: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '直播课',
        },
        {
          value: 2,
          label: '小班课',
        },
        {
          value: 3,
          label: '录播课',
        },
        {
          value: 4,
          label: '系列课',
        },
        {
          value: 5,
          label: '公开课',
        },
        {
          value: 6,
          label: '知识商品',
        },
        {
          value: 7,
          label: '图文',
        },
      ],
    }
  },

  filters: {
    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '知识商品'
          break
        case 7:
          text = '图文'
          break
      }
      return text
    },
  },

  props: {
    installment_id: [String],
  },

  methods: {
    // 移除
    remove(row) {
      this.$confirm(
        '移除商品后，使用免息券时将不显示该商品的免息信息，已通过免息券购买的商品不受影响！',
        '移除商品',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/Installment/removeGoods',
            data: {
              installment_goods_id: row.installment_goods_id,
            },
            callback: () => {
              this.$refs.childDialogBox.reset()
              this.$root.prompt({
                type: 'success',
                msg: '操作成功',
              })
            },
          })
        })
        .catch(() => {})
    },

    // 添加商品的回调
    singleselectcontentval(val) {
      this.list = val

      let course_id = this.list.filter(item => item.course_id)
      let open_class_id = this.list.filter(item => item.open_class_id)
      let live_goods_id = this.list.filter(item => item.live_goods_id)

      let post = {
        course_id: course_id.map(item => item.course_id),

        open_class_id: open_class_id.map(item => item.open_class_id),

        live_goods_id: live_goods_id.map(item => item.live_goods_id),

        installment_id: this.installment_id,

        graphic_id: this.list
          .filter(item => item.graphic_id)
          .map(item => item.graphic_id),
      }

      this.$http({
        url: '/Installment/addGoods',
        data: post,
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
        },
      })
    },

    // 添加商品
    add() {
      this.networkschoolSelectStatus = true
    },

    async resetList() {
      try {
        await this.$http({
          url: '/Installment/refreshGoods',
          data: {
            installment_id: this.installment_id,
          },
        })
        this.$refs.childDialogBox.reset()
      } catch (e) {
        console.log(e)
      }
    },

    complete(val) {
      // specsNumber
      this.specs = val.specs == 1 ? '3' : val.specs == 2 ? '6' : '12'
      let text = ''
      switch (Number(val.specs)) {
        case 1:
          text = '1.80%'
          break
        case 2:
          text = '4.50%'
          break
        case 3:
          text = '7.50%'
          break
      }
      this.specsNumber = text
    },

    //排序
    sortChange(value) {
      this.form = _.assign({}, this.form, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#commodityManagement {
  margin: 20px;

  .childDialogBox {
    .header-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .table {
      .menber {
        display: flex;
        align-items: center;
        .tu {
          margin-right: 14px;
          width: 70px;
          height: 39px;
          min-width: 70px; 
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          flex: 1;
          font-size: 13px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
