<!--
 * @Author: cyy
 * @Date: 2022-01-13 16:24:27
 * @LastEditTime: 2022-01-17 17:21:08
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\detail\operationLog.vue
-->
<template>
  <div id="operationLog">
    <pagination2 :option="post" url="/Installment/logList" ref="childDialogBox">
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="用户">
            <template slot-scope="{ row }">
              <div class="member">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name" :title="row.uname">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="content"></el-table-column>
          <el-table-column label="登录IP" prop="ip"></el-table-column>
          <el-table-column label="时间">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'operationLog',
  data() {
    return {
      post: {
        installment_id: this.installment_id,
      },
    }
  },
  props: {
    installment_id: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
#operationLog {
  margin: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  .member {
    display: flex;
    align-items: center;
    .tu {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 10px;
    }
    .name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>