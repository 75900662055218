<!--
 * @Author: cyy
 * @Date: 2022-01-13 16:24:27
 * @LastEditTime: 2022-01-17 18:19:21
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\detail\voucherManagement.vue
-->
<template>
  <div id="voucherManagement">
    <pagination2
      :option="form"
      url="/Installment/usedList"
      ref="childDialogBox"
      class="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <div class="header-search">
          <el-button type="primary" @click="grantDialogStatus = true">
            发放券
          </el-button>
          <div>
            <el-select
              v-model="form.status"
              style="width: 120px"
              class="mr20"
              placeholder="请选择"
              size="medium"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              size="medium"
              v-model="form.name"
              style="width: 218px"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
        </div>
        <el-table
          @sort-change="sortChange"
          class="table"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="学员">
            <template slot-scope="{ row }">
              <div class="menber" @click="newopen(row)">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name" :title="row.uname">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手机号" prop="umobile">
            <template slot-scope="{ row }">
              {{ row.umobile || '-' }}
            </template>
          </el-table-column>
          <el-table-column
            label="领取时间"
            prop="receive_time"
            sortable="custom"
          >
            <template slot-scope="{ row }">
              {{ row.receive_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="领取来源" prop="source">
            <template slot-scope="{ row }">
              {{ row.source }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              <p>
                {{ row.status | getstatus }}
              </p>
              <p class="redcolor fs12" v-if="row.status == 1">
                {{ row.receive_duration | formatTimeStamp }}后失效
              </p>
            </template>
          </el-table-column>
          <el-table-column label="用券时间" prop="used_time" sortable="custom">
            <template slot-scope="{ row }">
              <span v-if="row.used_time">
                {{ row.used_time | formatTimeStamp }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="发券人" prop="send_name">
            <template slot-scope="{ row }">
              <div class="menber">
                <div class="tu">
                  <img :src="row.send_photo" alt="" />
                </div>
                <div class="name" style="color: #333333">
                  {{ row.send_name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <!-- :disabled="row.status != 2" -->
              <el-button
                type="text"
                @click="toVoid(row)"
                :disabled="row.status != 1"
              >
                作废
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="detail(row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 发放 -->
    <grantDialog
      v-if="grantDialogStatus"
      :dialogstatus.sync="grantDialogStatus"
      :grantDialogVal="grantDialogVal"
    ></grantDialog>

    <!-- 详情抽屉 -->
    <el-drawer :visible.sync="drawer" size="378px" :show-close="false">
      <div class="drawer-contain">
        <div class="item">
          <div class="tu">
            <img :src="usedDetails.uphoto" alt="" />
          </div>
          <div class="font1">{{ usedDetails.uname }}</div>
        </div>
        <div class="item">
          <div class="font2">手机号：</div>
          <div class="font1">
            <span v-if="usedDetails.umobile > 0">
              {{ usedDetails.umobile }}
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="item">
          <div class="font2">领取时间：</div>
          <div class="font1">
            {{ usedDetails.receive_time | formatTimeStamp }}
          </div>
        </div>
        <div class="item">
          <div class="font2">状态：</div>
          <div class="font1">{{ usedDetails.status | getstatus }}</div>
        </div>
        <div class="item">
          <div class="font2">使用时间：</div>
          <div class="font1">
            <span v-if="usedDetails.used_time > 0">
              {{ usedDetails.used_time | formatTimeStamp }}
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="item2">
          <span class="title">购买商品：</span>
          <div v-if="usedDetails.name">
            <div class="course">
              <div class="c-tu">
                <img :src="usedDetails.cover_img" alt="" />
                <div class="course-type">
                  {{ usedDetails.course_type | getcourseType }}
                </div>
              </div>
              <div class="right-contain">
                <div class="name" :title="usedDetails.name">
                  {{ usedDetails.name }}
                </div>
                <div class="money">￥ {{ usedDetails.price }}</div>
              </div>
            </div>
          </div>
          <span v-else>-</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import grantDialog from '../grantDialog'
export default {
  name: 'voucherManagement',

  components: {
    grantDialog,
  },

  data() {
    return {
      //详情数据
      usedDetails: {},
      drawer: false,
      grantDialogStatus: false,
      grantDialogVal: {
        installment_id: this.installment_id,
      },
      form: {
        installment_id: this.installment_id,
        status: 0,
        name: '',
        field: '',
        sort: '',
      },
      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 2,
          label: '已使用',
        },
        {
          value: 1,
          label: '未使用',
        },
        {
          value: 3,
          label: '已失效',
        },
      ],
    }
  },

  filters: {
    // getsource(val) {
    //   let text = ''
    //   switch (Number(val)) {
    //     case 1:
    //       text = '公开课'
    //       break
    //     case 2:
    //       text = '链接'
    //       break
    //   }
    //   return text
    // },
    getcourseType(val) {
      // 1 直播 2小班 3录播 4系列 5公开课 6知识商品
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '知识商品'
          break
      }
      return text
    },
    getstatus(val) {
      // 1 未使用 2 已使用 3 已失效
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '未使用'
          break
        case 2:
          text = '已使用'
          break
        case 3:
          text = '已失效'
          break
      }
      return text
    },
  },

  props: {
    installment_id: [String],
  },

  methods: {
    // 前往学员详情页
    newopen(row) {
      let url = this.$router.resolve({
        path: '/manageStudent/detail',
        query: { uid: row.uid },
      })
      this.$store.dispatch('open', url.href)
    },

    // 详情
    detail(row) {
      this.$http({
        url: '/Installment/usedDetails',
        data: {
          installment_used_id: row.installment_used_id,
        },
        callback: ({ data }) => {
          this.usedDetails = data
          this.drawer = true
        },
      })
    },

    // 作废
    toVoid(row) {
      this.$confirm(
        '未使用的免息券可以作废，作废后该学员将不再享有免息券的优惠，是否确定作废？',
        '作废免息券',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          try {
            this.$http({
              url: '/Installment/cancel',
              data: {
                installment_used_id: row.installment_used_id,
              },
              callback: () => {
                this.$emit('toVoid')
                this.$refs.childDialogBox.reset()
                this.$root.prompt({
                  type: 'success',
                  message: '操作成功',
                })
              },
            })
          } catch (e) {
            console.log(e)
          }
        })
        .catch(() => {})
    },

    //排序
    sortChange(value) {
      this.form = _.assign({}, this.form, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        field: !value.order ? '' : value.prop == 'receive_time' ? 1 : 2,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#voucherManagement {
  padding: 20px;
  .header-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .childDialogBox {
    .table {
      .menber {
        display: flex;
        align-items: center;
        cursor: pointer;
        .tu {
          min-width: 24px;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          flex: 1;
          font-size: 14px;
          color: #3d80ef;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      ::v-deep .el-divider {
        background-color: #0aa29b;
      }
    }
  }
  .drawer-contain {
    padding: 20px;
    padding-bottom: 0;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      color: #333333;
      .tu {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-right: 10px;
        min-width: 24px;
        img {
          width: 100%;
          height: 100%;
        }
        .font1 {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .item2 {
      font-size: 14px;
      color: #333333;
      .title {
        margin-bottom: 12px;
      }
      .course {
        margin-top: 10px;
        display: flex;
        .c-tu {
          width: 70px;
          height: 39px;
          min-width: 70px;
          background: #e4e4e4;
          margin-right: 14px;
          position: relative;
          .course-type {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            text-align: center;
            width: 100%;
            font-size: 12px;
            padding: 2px 0 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right-contain {
          overflow: hidden;
          flex: 1;
          .name {
            font-size: 13px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .money {
            font-size: 13px;
            color: #ff3535;
            margin-top: 14px;
          }
        }
      }
    }
  }
}
</style>